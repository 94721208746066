import React, {useState} from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {format as formatDate} from 'date-fns';
import {formatCcy} from '../utils/utils';
import ExpensesCell from "./ExpensesCell";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Optional from "../UtilComponents/Optional";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Popper from '@material-ui/core/Popper';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import {Box} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BankIcon from "../UtilComponents/BankIcon";

const useStyles = makeStyles(theme => ({
    noWrap: {
        whiteSpace: "noWrap",
    },
    spacerButtonHack: {
        visibility: 'hidden',
        width: '1px',
    },
    iconButtonRoot: {
        padding: theme.spacing(1),
        height: theme.spacing(5),
        width: theme.spacing(5),
    },
    descriptionButtons: {
        marginTop: '1px',
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    accountInfo: {
        flexWrap: 'nowrap',
    },
    checkbox: {
        marginLeft: theme.spacing(-1),
    },
    row: {
        height: theme.spacing(5),
    },
    box: {
        width: theme.spacing(4),
    },
}));

function TransactionRow({
                            txn,
                            accounts,
                            categories,
                            openAddRuleDialog,
                            handleTxnSelected,
                            txnSelected,
                            multiSelectMode,
                            handleSingleSaveSuccess,
                        }) {
    const classes = useStyles();

    const [isHover, setIsHover] = useState(false);
    const [amountTdRef, setAmountTdRef] = useState(null);
    const [expensesTdRef, setExpensesTdRef] = useState(null);

    const openGoogleSearch = function (description) {
        window.open('http://www.google.co.uk/search?q=' + encodeURIComponent(description));
    };

    const showCheckboxOrAvatar = (
        isHover || multiSelectMode ?
            <Checkbox size={"small"} className={classes.checkbox} onChange={handleTxnSelected}
                      checked={txnSelected}/> :
            <BankIcon accountParser={accounts[txn.accountId].parser} smallIcon/>
    );
    return (
        <TableRow key={txn.id} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
                  className={classes.row}>
            <TableCell>
                <Grid container alignItems="center" className={classes.accountInfo}>
                    <Grid item className={classes.box}>{showCheckboxOrAvatar}</Grid>
                    <Grid item>
                        <Typography variant="body2" noWrap>{accounts[txn.accountId].name}</Typography>
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell align="right" className={classes.noWrap}>
                {formatDate(new Date(txn.dateTsMs), "E do LLL ''yy")}
            </TableCell>
            <TableCell>
                <span>{txn.description}</span>
                <Popper open={isHover} anchorEl={amountTdRef} popperOptions={{placement: 'left'}}>
                    <Box>
                        <Optional hidden={txn.expenses.length > 0}>
                            <IconButton variant="contained" color="secondary" classes={{root: classes.iconButtonRoot}}
                                        onClick={() => openAddRuleDialog(txn.description, txn.amount)}>
                                <AddIcon fontSize="small"/>
                            </IconButton>
                        </Optional>
                        <IconButton variant="contained" color="secondary" classes={{root: classes.iconButtonRoot}}
                                    onClick={() => openGoogleSearch(txn.description)}>
                            <SearchIcon fontSize="small"/>
                        </IconButton>
                    </Box>
                </Popper>
            </TableCell>
            <TableCell align="right" ref={setAmountTdRef}
                       className={classes.noWrap}>{formatCcy(txn.amount / 100)}</TableCell>
            <TableCell ref={setExpensesTdRef}>
                <ExpensesCell categoryPickerId={txn.id.toString()}
                              transactionId={txn.id}
                              transactionAmount={txn.amount}
                              transactionDescription={txn.description}
                              expenses={txn.expenses}
                              users={accounts[txn.accountId].users}
                              categories={categories}
                              popperAnchorEl={expensesTdRef}
                              isMouseOver={isHover}
                              forceMouseOut={() => setIsHover(false)}
                              handleSingleSaveSuccess={handleSingleSaveSuccess}
                />
            </TableCell>
        </TableRow>
    );
}

TransactionRow.propTypes = {
    txn: PropTypes.object.isRequired,
    accounts: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    openAddRuleDialog: PropTypes.func.isRequired,
    handleTxnSelected: PropTypes.func.isRequired,
    txnSelected: PropTypes.bool.isRequired,
    multiSelectMode: PropTypes.bool,
    handleSingleSaveSuccess: PropTypes.func.isRequired,
};

export default TransactionRow;
