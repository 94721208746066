import * as PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar/Avatar";
import React from "react";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import BankIcon from "./BankIcon";

function AccountName(props) {
    return <Grid container alignItems="center">
        <Grid item>
            <BankIcon accountParser={props.accountParser} smallIcon={props.smallIcon} withMargin/>
        </Grid>
        <Grid item>
            {props.accountName}
        </Grid>
    </Grid>;
}

AccountName.propTypes = {
    accountName: PropTypes.string.isRequired,
    accountParser: PropTypes.string.isRequired,
    smallIcon: PropTypes.bool,
};

AccountName.defaultProps = {
    smallIcon: true,
};

export default AccountName;
