import * as PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar/Avatar";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    defaultMargin: {
        marginRight: theme.spacing(2),
    },
    smallIcon: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    smallMargin: {
        marginRight: theme.spacing(1),
    },
}));

function BankIcon(props) {
    const classes = useStyles();
    const className = [
        props.className || '',
        props.smallIcon ? classes.smallIcon : '',
        props.withMargin ? (props.smallIcon ? classes.smallMargin : classes.defaultMargin) : '',

    ].join(' ');
    return <Avatar className={className}
                   alt={props.accountParser}
                   src={`bank-icons/${props.accountParser.toLowerCase()}.png`}/>;
}

BankIcon.propTypes = {
    accountParser: PropTypes.string.isRequired,
    smallIcon: PropTypes.bool,
    withMargin: PropTypes.bool,
    className: PropTypes.string,
};

export default BankIcon;
